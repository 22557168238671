import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className="hero">
      <h1>Protect Your Privacy with DVPN</h1>
      <p>Fast, secure, and reliable VPN service that ensures your privacy and freedom online.</p>
      <button className="cta-button">Get Started Now</button>
    </section>
  );
};

export default HeroSection;
