import React from "react";
import { Route, Routes } from "react-router-dom"; // Импортируем Route и Routes для маршрутизации
import Header from "./components/Header"; // Импортируем компонент заголовка
import HeroSection from "./components/HeroSection"; // Импортируем компонент главного раздела
import Features from "./components/Features"; // Импортируем компонент функций
import Footer from "./components/Footer"; // Импортируем компонент подвала
import AboutPage from "./components/AboutPage"; // Импортируем компонент страницы "О нас"
import "./App.css"; // Импортируем основные стили

function App() {
  return (
    <div className="app-container">
      {/* Компонент заголовка */}
      <Header />

      {/* Определяем маршруты */}
      <Routes>
        {/* Главная страница */}
        <Route path="/" element={
          <>
            <HeroSection />
            <Features />
          </>
        } />
        
        {/* Страница "О нас" */}
        <Route path="/about" element={<AboutPage />} />
      </Routes>

      {/* Компонент подвала сайта */}
      <Footer />
    </div>
  );
}

export default App;
