import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 DVPN Service. All rights reserved.</p>
      <nav className="footer-nav">
        <a href="#privacy">Privacy Policy</a>
        <a href="#terms">Terms of Service</a>
        <a href="#support">Support</a>
      </nav>
    </footer>
  );
};

export default Footer;