import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './assets/logo.svg';

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <h1 className="logo-title">DVPN</h1>
        <img src={logo} alt="Логотип" className="logo-svg" />
      </div>
      <nav className="nav">
        {/* Кнопка "Купить" теперь первой */}
        <Link to="/buy" className="nav-buy-button">Купить</Link>

        {/* Остальные ссылки и кнопки */}
        <Link to="/" className="nav-link">Главная</Link>
        <Link to="/about" className="nav-button">О нас</Link>
        <Link to="/contact" className="nav-link">Связаться</Link>
      </nav>
    </header>
  );
};

export default Header;
