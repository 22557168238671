// src/components/AboutPage.js
import React from 'react';
import './AboutPage.css'; // Если у вас есть стили для AboutPage

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>О нас</h1>
      <div className="about-content">
        <p>
          Здесь будет информация о вашей организации. Вы можете описать свои цели,
          ценности и что отличает вас от других.
        </p>
        <p>
          Примерный текст может включать:
        </p>
        <ul>
          <li>История вашей компании</li>
          <li>Миссия и видение</li>
          <li>Команда и ключевые члены</li>
          <li>Список услуг или продуктов</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutPage;
