import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <section id="features" className="features">
      <h2>Why Choose DVPN?</h2>
      <div className="features-container">
        <div className="feature-item">
          <h3>Fast Connections</h3>
          <p>Our servers ensure lightning-fast connection speeds for seamless browsing.</p>
        </div>
        <div className="feature-item">
          <h3>Top Security</h3>
          <p>Military-grade encryption protects your data and privacy.</p>
        </div>
        <div className="feature-item">
          <h3>Worldwide Servers</h3>
          <p>Access content from anywhere with our vast network of global servers.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;