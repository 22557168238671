import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; // Импортируем BrowserRouter
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> {/* Оборачиваем App в BrowserRouter */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Если вы хотите начать измерять производительность в вашем приложении,
// передайте функцию для логирования результатов (например: reportWebVitals(console.log))
// или отправьте на аналитический конечный пункт. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();
